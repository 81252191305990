.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 400px;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    z-index: 1001;
}

label {
    display: block;
    margin: 10px 0;
}

input {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
}

button {
    margin: 10px;
}
.channel-names {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

.channel-item {
    margin: 5px;
}

.channel-item button {
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0; /* Default button color */
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.channel-item button.selected {
    background-color: #4caf50; /* Change to your desired selected color */
    color: white;
}

.channel-dropdown {
    position: relative;
    margin: 10px 0;
}

.channel-dropdown input {
    width: 100%;
    padding: 10px;
    cursor: pointer;
}

.dropdown-list {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 100%;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto; /* Allows scrolling if the list is long */
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-item:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.dropdown-item.selected {
    background-color: #4caf50; /* Selected background color */
    color: white;
}
