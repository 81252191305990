.research-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* General section styling */
.section {
  flex: 1; /* Each section takes equal space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

/* Part 1: Title Section */
.title-section {
  background-color: #f0f8ff; /* Light blue */
}

.title-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.title-section p {
  font-size: 1.2rem;
  color: #555;
}

/* Part 2: Data Section */
.data-section {
  background-color: #ffffff; /* White */
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e0e0e0;
  border: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.button.selected {
  background-color: #1e90ff; /* Blue color for selected state */
}

.dashboard-content {
  margin-top: 20px;
}

/* Part 3: Contact Section */
.contact-section {
  background-color: #f9f9f9; /* Light gray */
}

.contact-section h2 {
  margin-bottom: 1rem;
}

.contact-section p {
  font-size: 1rem;
  color: #555;
}

/* Part 4: Logo Section */
.logo-section {
  background-color: #e6e6e6; /* Light gray */
}

.logos {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.logos img {
  width: 80px;
  height: auto;
}
